@tailwind base;
@tailwind components;
@tailwind utilities;

details summary::-webkit-details-marker {
  display: none;
}

/* Landing Page version of header and footer*/
body:has(.is-landingPage) .global-header nav {
  visibility: hidden;
}

body:has(.is-landingPage) .global-header .default-link {
  display: none;
}

body:has(.is-landingPage) .global-header .landing-page-link {
  display: block;
}

body:has(.is-landingPage) .global-header .banner,
body:has(.is-landingPage) .global-header .mobile-menu,
body:has(.is-landingPage) .footer .footer-links {
  display: none;
}

body:has(.is-landingPage) .global-header .homepage-logo {
  pointer-events: none;
}

/* Chilipiper Popup positioning */
.chilipiper-popup {
  align-items: flex-start !important;
}

.chilipiper-popup .chilipiper-popup-window {
  position: relative !important;
}

.chilipiper-popup .chilipiper-frame {
  position: absolute !important;
  top: 0 !important;
}

.chilipiper-popup .chilipiper-popup-window .routing-loader {
  max-width: inherit !important;
  max-height: inherit !important;
  margin-top: 100px !important;
}

.chilipiper-popup .chilipiper-popup-window .routing-loader > div {
  display: flex !important;
}

.chilipiper-popup .chilipiper-popup-loading {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

/* Event Carousel */
.event-swiper .swiper-button-next,
.event-swiper .swiper-button-prev {
  color: #9e91ff;
  width: 40px;
  height: 40px;
}

.event-swiper .swiper-button-disabled {
  opacity: 0.35;
  cursor: not-allowed;
}
